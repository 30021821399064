<!--短信记录-->
<template>
    <div class="account-model">
        <div class="global-header">
            <el-form inline :model="filterInfo">
                <el-form-item label="用户ID">
                    <el-input
                            v-model="filterInfo.userId"
                            placeholder="用户ID"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="发送的手机号">
                    <el-input
                            v-model="filterInfo.sendMobile"
                            placeholder="发送的手机号"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="">
                    <el-button
                            :loading="tableLoad"
                            type="primary"
                            icon="el-icon-search"
                            @click="search({ pageNum: 1, pageSize: pageInfo.pageSize })"
                    >搜 索
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="global-view-table">
            <el-table v-loading="tableLoad" border :data="tableData" size="mini">
                <el-table-column prop="userId" label="用户ID" width="60px"/>
                <el-table-column prop="sendMobile" label="发送的手机号" width="100px"/>
                <el-table-column prop="sendText" label="发送内容" width="300">
                    <template slot-scope="{row}">
                        <el-tooltip placement="top">
                            <div slot="content" style="max-width: 360px;">{{row.sendText}}</div>
                            <div style="width: 280px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{row.sendText}}</div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="sendCount" label="发送短信数量" width="100"/>
                <el-table-column prop="smsChannelName" label="通道名称"/>
                <el-table-column prop="smsTypeCode" label="短信类型编码" width="160"/>
                <el-table-column prop="smsTypeName" label="短信类型名称" width="120"/>
                <el-table-column prop="templateCode" label="短信模板" width="160"/>
                <el-table-column prop="resultJson" label="运营商返回结果" width="145" show-overflow-tooltip/>
                <el-table-column prop="sendTime" label="发送时间" width="145"/>
                <el-table-column prop="sendStatus" label="状态" fixed="right" width="65">
                    <template slot-scope="{ row }">
                        <el-tag type="success" v-if="row.sendStatus == 1">成功</el-tag>
                        <el-tag type="danger" v-if="row.sendStatus == 0">失败</el-tag>
                    </template>
                </el-table-column>
            </el-table>

            <GPagination
                    :total="total"
                    :current-page="pageInfo.pageNum"
                    :size="pageInfo.pageSize"
                    @change="changePage"
            />
        </div>
    </div>
</template>

<script>
    import {
        getSmsRecord
    } from "@/api/business";

    import GPagination from "@/components/GPagination";
    import {filterFormat} from '@/utils/tools'

    export default {
        name: "account-list",
        components: {
            GPagination,
        },
        data() {
            return {
                tableLoad: false,
                tableData: [],
                total: 0,
                pageInfo: {
                    pageNum: 1,
                    pageSize: 20,
                },
                filterInfo: {
                    userId: null,
                    sendMobile: ''
                }
            };
        },
        created() {
            this.search(this.pageInfo);
        },
        methods: {
            changePage(page) {
                this.search(page);
            },

            search(page) {
                Object.assign(this.pageInfo, page);
                this.tableLoad = true;
                getSmsRecord({
                    ...this.pageInfo,
                    ...filterFormat(this.filterInfo),
                })
                    .then((res) => {
                        const {total, records} = res.data;
                        this.tableData = records;
                        this.total = total;
                        this.tableLoad = false;
                    })
                    .catch(() => {
                        this.tableLoad = false;
                    });
            }
        },
    };
</script>

<style lang="scss">
    .account-model {
        width: 100%;
        height: 100%;
        padding: 16px;
        overflow-y: auto;
        .global-view-table {
            height: auto;
            margin-top: 16px;
            padding: 16px;
        }
        .role-tag {
            margin-right: 8px;
        }
    }

    .account-dialog {
        .el-dialog__body {
            padding: 24px 24px 0;
        }
    }
</style>
